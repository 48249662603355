import { createRouter, createWebHistory } from "vue-router";
import store from "../store";



import HomeView from "../views/HomeView.vue";
import FormView from "../views/FormCreateView.vue";
import ListView from "../views/admindashboard.vue";
import testView from "../views/testView.vue"




const routes = [{
        path: "/",
        name: "home",
        component: HomeView,

    },
    {
        path: "/forms",
        name: "Forms",
        component: FormView,
    },
    {
        path: "/list-view",
        name: "ListView",
        component: ListView,
    },
    {
        path: "/test-page",
        name: "testView",
        component: testView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;