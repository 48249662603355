<template lang="">
<div class="min-h-screen bg-gray-300">
    <div class="w-full h-full max-w-5xl bg-white mx-auto flex flex-col  gap-2 p-10 relative">
      <div class="flex gap-4 my-2">
            <input type="text" :question_attr="this.idval" class="questions w-[60%] border-0 border-b-2 border-green-700" id="questions_val"
                placeholder="Question" required>
            <select name="selected_val" :selected_attr="this.idval" id="selected_val" @change="typeselect" class="w-[30%] rounded border-gray-100">
                <option value="multiple choice">Multiple choice</option>
                <option value="checkboxes"> Checkboxs</option>
                <option value="rating">Rating</option>
                <option value="description">Paragraph</option>
            </select>
        </div>
      
    <div class="flex flex-col gap-4">
      <div class="textappend w-[460px] flex flex-col gap-2">
          <div class="flex gap-4 justify-between inputtext px-1">
            <input type="text" class="block w-[420px] border-0 border-b border-green-700">
          </div>
        </div>  
        <div class="text-start px-4 cursor-pointer">
          <p @click="addele" class="text-sm text-blue-400">add option</p>
          </div>
    </div>
    </div>
    </div>
</template>
<script>
export default {
  methods: {
    addele() {
      let inputFile = document.querySelector(".inputtext").cloneNode(true);
      inputFile.childNodes[0].value = "";
      let clsElement = document.createElement("button");
      clsElement.addEventListener("click", this.closebtn);
      clsElement.innerHTML = "X";
      inputFile.appendChild(clsElement);
      let container = document.querySelector(".textappend");
      let children = document.createElement("input");
      children.type = "text";
      children.className = "block w-[50%] border-0 border-b border-green-700"; // set the CSS class
      container.appendChild(inputFile); // put it into the DOM
    },
    closebtn(el) {
      console.log(el.target.parentNode.remove());
    },
  },
};
</script>
<style lang="">
</style>