<template>
  <div class="min-h-screen bg-white">
    <NavigationView />
    <div
      class="
        my-2
        w-full
        h-full
        max-w-5xl
        bg-[#2b2a48]
        mx-auto
        flex flex-col
        gap-2
        p-0 
        md:p-10
        relative
        rounded-md
      "
    >
      <form @submit.prevent="addquestion" class="h-full flex flex-col gap-2">
        <input
          type="text"
          placeholder="Title"
          class="title border-0 border-b-2"
        />
        <template v-for="(question, index) in questions" :key="question">
          <div
            class="
              flex flex-col
              gap-4
              p-0
              md:p-8
              m-2
              rounded-md
              shadow-xl
              border border-gray-200 bg-white
              relative
            "
          >
            <formscomp :idval="index" />
            <div v-if="index !=0" class="text-lg cursor-pointer text-indigo-700 font-bold hover:text-indigo-900 absolute top-6 right-6" @click="rmelement">
              X
            </div>
          </div>
        </template>
        <div class="px-2 w-full float-right">
          <img @click="++questions"
            src="../assets/add.png"
            alt=""
            class="
              h-10
              w-10
              border
              bg-white
              p-2
              float-right
              hover:border-[#2b2a48]
              cursor-pointer
            "
          />
        </div>
        <div>
          <button class="px-4 py-2 border rounded-md bg-[#2b2a48] text-white hover:text-[#2b2a48] hover:bg-white">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import formscomp from "@/components/formsView.vue";
import NavigationView from '@/components/navigationView.vue'
export default {
  data() {
    return {
      questions: 1,
      question_str: {
        name: "",
        description: "",
        main_froms: [],
      },
    };
  },
  components: {
    formscomp,
    NavigationView
  },
  methods: {
    addquestion(el) {
      let question_dev = document.querySelectorAll(".questions");
      question_dev.forEach((el) => {
        let answer_str = {
          question: "",
          choices: "",
          order: "",
          multiple_choice: [],
          Rating: [],
          description: [],
          checkboxes: [],
        };
        let formName = document.querySelector(".title").value;
        let question_id = el.getAttribute("question_attr");
        let question_val = el.value;
        answer_str["question"] = question_val;
        let answer_type = document.querySelectorAll(
          `[selected_attr="${question_id}"]`
        );
        let answer_id = document.querySelectorAll(
          `[answer_attr="${question_id}"]`
        );
        answer_str["choices"] = answer_type[0].value;
        answer_id.forEach((el) => {
          if (answer_type[0].value == "multiple choice") {
            let answer_text = {
              answer_text: el.value,
            };
            answer_str.multiple_choice.push(answer_text);
          } else if (answer_type[0].value == "checkboxes") {
            let answer_text = {
              answer_text: el.value,
            };
            answer_str.checkboxes.push(answer_text);
          } else if (answer_type[0].value == "rating") {
            let answer_text = {
              rating: el.value,
            };
            answer_str.Rating.push(answer_text);
          }
        });

        this.question_str.main_froms.push(answer_str);
        this.question_str["name"] = formName;
      });
      console.log(this.question_str, "output");
      localStorage.setItem('forms_output', JSON.stringify(this.question_str))
    },
    rmelement(el){
      el.target.parentNode.remove()
    }
  },
};
</script>

<style scoped>

</style>