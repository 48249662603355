<template lang="">
    <div class="w-full h-20 mx-auto bg-[#2b2a48]">
        <div class="flex h-full justify-between px-10 py-2 items-center">
            <div class="px-2">
                <img src="@/assets/Maggana.png" class="h-12" />
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>