import { createStore } from "vuex";
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        
    },
    getters: {
        
    },
    actions: {
        
    },
    mutations: {
        
    },
    modules: {},
    plugins: [createPersistedState()],
});