<template>
  <div class="w-full min-h-screen mx-auto bg-white">
    <NavigationView />
    <div class="max-w-5xl w-full mx-auto my-2 p-10 min-h-screen rounded-md bg-[#2b2a48]">
      <div class="w-[800px] mx-auto">
        <h1 class="text-start font-bold text-white">Form list</h1>
        <template v-for="f in this.data_out[0].data" :key="f">
          <div
            class="
              h-auto
              p-3
              mx-auto
              flex
              items-center
              justify-between
              bg-white
              border
              my-2
              rounded-lg
            "
          >
            <h1 class="px-4 text-start text-base capitalize">{{ f.name }}</h1>
            <div class="flex justify-end">
              <button class="p-2 text-right inline">
                {{
                  new Date(f.created_at).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from "@/components/navigationView.vue";
export default {
  data() {
    return {
      data_out: [
        {
          message: "Have a details",
          status: 200,
          data: [
            {
              user: 1,
              name: "python Form",
              "created_at": "2023-01-05T21:38:32+05:30", "updated_at": "2023-01-05T21:38:32+05:30",
              description:
                "Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis.",
            },
            {
              user: 1,
              name: "python Form2",
              "created_at": "2023-01-05T21:38:32+05:30", "updated_at": "2023-01-05T21:38:32+05:30",
              description:
                "Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis.",
            },
          ],
        },
      ],
    };
  },
  components: {
    NavigationView,
  },
};
</script>

<style>
</style>