<template>
    <div class="min-h-screen bg-gray-100">
        <NavigationView />
        <div class="max-w-5xl bg-[#2b2a48] w-full mx-auto flex flex-col gap-2 p-10 rounded-md my-2">
            <form class="w-full h-full flex flex-col gap-4" @submit.prevent="frmsub">
                <!-- {{data_in[0].data[0].question}} -->
                <h1 class="text-start text-2xl font-bold capitalize text-white">{{ data_in[0].data[0].name }}</h1>
                <div class="text-start px-4 bg-white py-2 rounded-md">
                    <label for="Email_id">MailId</label>
                    <input type="text" id="user_mail" class="block w-[50%] border-0 border-b border-green-700">
                </div>
                <div v-for="i in data_in[0].data[0].question" :key="i" class="text-start px-4 bg-white py-2 rounded-md">
                    <div class="py-2">
                        <!-- MCQ -->
                        <div v-if="i.choices_type == 'multiple choice'">
                            <!-- question -->
                            <h1 class="questions_name text-2xl font-semibold p-2" :que_id="i.id">
                                {{ i.question_text }}
                            </h1>
                            <div class="">
                                <div v-for="j in i.multiplechoice" :key="j"
                                    class="p-2 border border-gray-200 m-2 rounded">
                                    <div class="flex gap-3 items-center">
                                        <input type="radio" class="answer_val" :question_id="i.id" :answer_id="j.id" choices_type="mcq" name="mcq_output">
                                        {{ j.answer_text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CheckBox -->
                        <div v-else-if="i.choices_type == 'checkboxe'">
                            <!-- question -->
                            <h1 class="questions_name text-2xl font-semibold p-2" :que_id="i.id">
                                {{ i.question_text }}
                            </h1>
                            <div>
                                <div v-for="j in i.checkboxe" :key="j" class="p-2 border border-gray-200 m-2 rounded">
                                    <input type="checkbox" class="answer_val" :question_id="i.id" :answer_id="j.id" choices_type="checkbox">
                                    <p class="inline px-4">{{ j.checkboxe }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Rating -->
                        <div v-else-if="i.choices_type == 'rating'">
                            <!-- question -->
                            <h1 class="questions_name text-2xl font-semibold p-2" :que_id="i.id">
                                {{ i.question_text }}
                            </h1>
                            <div class="flex justify-evenly m-4">

                                <div v-for="j in parseInt(i.rating[0].rating)" :key="j" class="">
                                    <div class="">
                                        <p class="text-center">
                                            {{ j }}
                                        </p>
                                        <input type="radio" class="answer_val" :question_id="i.id" :value="j" name="radio_output" choices_type="rating">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- description -->
                        <div v-else-if="i.choices_type == 'description'">
                            <!-- question -->
                            <h1 class="questions_name text-2xl font-semibold p-2" :que_id="i.id">
                                {{ i.question_text }}
                            </h1>
                            <div class="p-4">
                                <textarea name="" :id="`descrip` + i.id" :question_id="i.id" class="w-full answer_val" cols="30" rows="10" choices_type="desc"
                                    ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button class="bg-[#2b2a48] text-white px-4 py-2 rounded border border-white hover:text-[#2b2a48] hover:bg-white" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import NavigationView from '@/components/navigationView.vue'
export default {
    data() {
        return {
            data_in: [{
                "message": "Have a details", "status": 200,
                "data": [{
                    "user": 1, "name": "python Form", "description": "Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis.",
                    "duration": 10, "questionlimt": 11, "image": null, "Assessment": null, "is_published": false,
                    "created_at": "2023-01-05T21:38:32+05:30", "updated_at": "2023-01-05T21:38:32+05:30",
                    "question": [{
                        "id": 1, "multiplechoice": [{ "id": 1, "answer_text": "Less than 6 months", "question": 1 }, { "id": 2, "answer_text": "1 Year", "question": 1 },
                        { "id": 3, "answer_text": "Less than 2 Years", "question": 1 }, { "id": 4, "answer_text": "More than 2 Years", "question": 1 }],
                        "rating": [], "description": [], "checkboxe": [], "choices_type": "multiple choice", "question_text": "No of months/years experience in Python.", "order": 1, "quiz": 1
                    },
                    {
                        "id": 2, "multiplechoice": [], "rating": [], "description": [], "checkboxe": [{ "id": 1, "checkboxe": "None", "question": 2 }, { "id": 2, "checkboxe": "Beginner", "question": 2 },
                        { "id": 3, "checkboxe": "Intermediate", "question": 2 }, { "id": 4, "checkboxe": "Expert", "question": 2 }], "choices_type": "checkboxe", "question_text": "State your Level with Python",
                        "order": 0, "quiz": 1
                    },
                    {
                        "id": 3, "multiplechoice": [], "rating": [{ "rating": "10" }], "description": [], "checkboxe": [], "choices_type": "rating", "question_text": "Your Rating for Python Course",
                        "order": 0, "quiz": 1
                    },
                    {
                        "id": 4, "multiplechoice": [], "rating": [], "description": [], "checkboxe": [], "choices_type": "description", "question_text": "Descripe about Python Course",
                        "order": 0, "quiz": 1
                    }]
                }]
            }],
            outputdata: []
        }
    },
    components:{
        NavigationView
    },
    methods: {
        frmsub(el) {
            
           let tempval = document.querySelectorAll('.questions_name')
           tempval.forEach((el,index)=>{
            let question_id = el.getAttribute('que_id')
            let answer_id= document.querySelectorAll(`[question_id="${question_id}"]`)
            answer_id.forEach((el)=>{
                if(el.attributes.choices_type.value == 'mcq' || el.attributes.choices_type.value == 'checkbox'){
                    if(el.checked == true){
                        let answer_val=el.attributes.answer_id
                        let tempval = {
                            question_value :question_id,
                            answer_value : answer_val
                        }
                        this.outputdata.push(tempval)
                    }
                }else if(el.attributes.choices_type.value == 'rating'){
                    if(el.checked == true){
                        let answer_val=el.value
                        let tempval = {
                            question_value :question_id,
                            answer_value : answer_val
                        }
                        this.outputdata.push(tempval)
                    }
                }else{
                    let answer_val=el.value
                        let tempval = {
                            question_value :question_id,
                            answer_value : answer_val
                        }
                        this.outputdata.push(tempval)
                } 
            })
           })
           console.log(this.outputdata)
            

        },
        
    }
}
</script>

<style>

</style>