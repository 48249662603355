<template>
  <div class="questionDev">
    <div class="flex flex-col md:flex-row gap-4 my-2">
      <input
        type="text"
        :question_attr="this.idval"
        class="questions w-full md:w-[60%] border-0 border-b-2 border-green-700"
        id="questions_val"
        placeholder="Question"
        required
      />
      <select
        name="selected_val"
        :selected_attr="this.idval"
        id="selected_val"
        @change="typeselect"
        class="w-full md:w-[30%] rounded border-gray-100"
      >
        <option value="multiple choice">Multiple choice</option>
        <option value="checkboxes">Checkboxs</option>
        <option value="rating">Rating</option>
        <option value="description">Paragraph</option>
      </select>
    </div>
    <div v-if="this.selected == 'multiple choice'">
      <div class="flex flex-col gap-4">
        <div
          :class="`textappend` + this.idval"
          class="min-w-[320px] w-full max-w-[460px] flex flex-col gap-2"
        >
          <div
            class="
              flex
              gap-4
              justify-between
              inputtext_mcq
              px-1
              items-center
              mcq
            "
          >
            <input
              type="radio"
              class="radio_in"
              :name="`radio_output` + this.idval"
            />
            <input
              type="text"
              id="answer_choices"
              :answer_attr="this.idval"
              class="block w-[420px] border-0 border-b border-green-700"
            />
          </div>
        </div>
        <div class="text-start cursor-pointer">
          <p
            @click="addele"
            class="
              text-sm text-indigo-700
              px-4
              py-1
              hover:text-indigo-900
              inline-block
            "
          >
            add option
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="this.selected == 'checkboxes'">
      <div class="flex flex-col gap-4">
        <div
          :class="`textappend` + this.idval"
          class="w-[460px] flex flex-col gap-2"
        >
          <div
            class="
              flex
              gap-4
              justify-between
              inputtext_checkbox
              px-1
              items-center
              checkbox
            "
          >
            <input type="checkbox" class="check_box" />
            <input
              type="text"
              class="block w-[420px] border-0 border-b border-green-700"
              :answer_attr="this.idval"
            />
          </div>
        </div>
        <div class="text-start cursor-pointer">
          <p
            @click="addele"
            class="
              text-sm text-indigo-700
              px-4
              py-1
              hover:text-indigo-900
              inline-block
            "
          >
            add option
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="this.selected == 'rating'">
      <div class="flex gap-2 items-center">
        <label for="range_val">Range</label>
        <input
          v-model.lazy="this.range_val"
          type="number"
          class="rating block border-0 border-b border-green-200"
          min="0"
          max="10"
          :answer_attr="this.idval"
        />
      </div>
      <div class="flex justify-evenly m-4">
        <div v-for="j in parseInt(this.range_val)" :key="j" class="">
          <div class="">
            <p class="text-center">
              {{ j }}
            </p>
            <input
              type="radio"
              class="answer_val"
              :value="j"
              name="radio_output"
              choices_type="rating"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <input
        type="text"
        class="block w-[420px] border-0 border-b border-green-700"
        :answer_attr="this.idval"
        placeholder="User answer..."
        disabled
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "multiple choice",
      range_val: 0,
    };
  },
  props: ["idval"],
  created() {},
  methods: {
    typeselect(el) {
      this.selected = el.target.value;
    },
    addele() {
      if (this.selected == "multiple choice") {
        
        let inputFile = document
          .querySelector(".inputtext_mcq")
          .cloneNode(true);
        let radio_btn = `radio_output${this.idval}`;
        inputFile.childNodes[0].setAttribute("name", radio_btn);
        inputFile.childNodes[1].value = "";
        inputFile.childNodes[1].setAttribute("answer_attr", this.idval);
      
        if (inputFile.querySelector(".closebtn")) {
          
          let btn = inputFile.querySelector(".closebtn");
          btn.remove();
          inputFile.appendChild(this.addclosebtn());

        } else {

          inputFile.appendChild(this.addclosebtn());

        }

        let className = `.textappend${this.idval}`;
        let container = document.querySelector(className);
        container.appendChild(inputFile);

        this.addclsToInput(container);
      } else {

          let inputFile = document
            .querySelector(".inputtext_checkbox")
            .cloneNode(true);

          let checkbox_btn = `check_output${this.idval}`;
          inputFile.childNodes[0].setAttribute("name", checkbox_btn);
          inputFile.childNodes[1].value = "";
          inputFile.childNodes[1].setAttribute("answer_attr", this.idval);

          if (inputFile.querySelector(".closebtn")) {
            let btn = inputFile.querySelector(".closebtn");
            btn.remove();
            inputFile.appendChild(this.addclosebtn());
          } else {
            inputFile.appendChild(this.addclosebtn());
          }

          let className = `.textappend${this.idval}`;
          let container = document.querySelector(className);
          container.appendChild(inputFile);

          this.addclsToInput(container);
      }
    },
    closebtn(el) {
      let className = `.textappend${this.idval}`;
      let container = document.querySelector(className);
      el.target.parentNode.remove();
      
      if (container.childNodes.length == 1) {
        console.log(container.querySelector(".closebtn").remove());
      }

    },
    addclsToInput(container) {
      
      if (container.childNodes.length == 2) {
        container.childNodes[0].appendChild(this.addclosebtn());
      }

    },
    addclosebtn() {
      let clsElement = document.createElement("button");
      clsElement.className =
        "closebtn font-bold text-indigo-700 font-bold hover:text-indigo-900";
      clsElement.addEventListener("click", this.closebtn);
      clsElement.type = "button";
      clsElement.innerHTML = "X";
      return clsElement;
    },
  },
};
</script>

<style >
</style>